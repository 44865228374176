import DocViewer, { IDocument } from "@cyntler/react-doc-viewer";
import { Button } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { PDFDocument } from "pdf-lib";
import "pdfjs-dist/web/pdf_viewer.css";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai"; // Import zoom icons
import { useSelector } from "react-redux";
import RequisitionType from "../../Services/Requisition/RequisitionTypeService";
import { Decrypt, Encrypt } from "../../Utils/Auth";
import useLang from "./../../Shared/hooks/useLanguage";
import store from "Redux/Store/AppStore";

const DocsViewer = () => {
  const { t } = useLang();
  const [pdfBlobURLs, setPdfBlobURLs] = useState<any[]>([]);
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const urls = useSelector((state: any) => state.Reducer.pdfurls);
  const [currentDocument, setCurrentDocument] = useState<any>(null);
  const [pdfUrl, setPdfUrl] = useState<any>(null);
  const [isZoomable, setIsZoomable] = useState(true);
  const xPortalKey = useSelector((state: any) => state.Reducer.labKey);

  useEffect(() => {
    const showFileInViewer = async (fileUrl: string) => {
      try {
        const res = await RequisitionType.ShowBlobInViewer(fileUrl);
        const fileData = res?.data?.Data;

        const isPdf = fileUrl.endsWith(".pdf");
        const isImage = fileUrl.match(/\.(jpg|jpeg|png)$/i);
        const isCsv = fileUrl.endsWith(".csv");
        const isExcel = fileUrl.endsWith(".xlsx") || fileUrl.endsWith(".xls");

        if (isPdf && fileData.length > 0) {
          setPdfUrls(fileData);
          mergeAndShowPdfs(fileData);
          setIsZoomable(true); // Enable zoom for PDFs
        } else if (isImage) {
          setImageUrl(fileData[0]?.uri);
          setIsZoomable(false); // Disable zoom for images
        } else if (isCsv || isExcel) {
          setIsZoomable(false); // Disable zoom for CSV and Excel files
          setPdfBlobURLs(fileData); // For CSV/Excel, show without zoom
        } else {
          setPdfBlobURLs(fileData);
          setIsZoomable(false); // Enable zoom for other file types
          setCurrentDocument(fileData[0]); // Set the first document as current document
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    const PdfUriLink = sessionStorage.getItem("pdfUriLink");
    const uri = PdfUriLink && Decrypt(PdfUriLink);
    if (uri) {
      showFileInViewer(uri);
    } else {
      sessionStorage.setItem("pdfUriLink", Encrypt(urls));
      showFileInViewer(urls);
    }
  }, [urls]);

  const handlePrint = () => {
    const showFileInNewTab = async (fileUrl: string) => {
      try {
        const res = await RequisitionType.ShowBlobInViewer(fileUrl);
        const fileData = res?.data?.Data;
        if (fileData.length === 1 && fileData[0].fileType !== "pdf") {
          window.location.assign(fileData[0]?.uri);
        } else {
          // If PDFs exist, merge and download
          const mergedPdfBytes = await mergePdfs(
            pdfUrls.map((data: any) => data.uri)
          );
          const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let splittedName = fileUrl.split(
            `https://truemedpo.blob.core.windows.net/${xPortalKey.toLowerCase()}-pdf/`
          );
          link.download = splittedName[splittedName.length - 1];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    const PdfUriLink = sessionStorage.getItem("pdfUriLink");
    const uri = PdfUriLink && Decrypt(PdfUriLink);
    if (uri) {
      showFileInNewTab(uri);
    } else {
      sessionStorage.setItem("pdfUriLink", Encrypt(urls));
      showFileInNewTab(urls);
    }
  };

  const mergeAndShowPdfs = async (fileData: any) => {
    const pdfUrl = fileData.map((data: any) => data.uri);
    const mergedPdfBytes = await mergePdfs(pdfUrl);
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    setPdfUrl(URL.createObjectURL(blob));
  };

  const mergePdfs = async (urls: any) => {
    const mergedPdf = await PDFDocument.create();

    for (const url of urls) {
      const response = await fetch(url);
      const pdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page: any) => mergedPdf.addPage(page));
    }

    return await mergedPdf.save();
  };
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const componentRef = useRef<any>();

  const renderViewer = () => {
    if (pdfUrls?.length > 0 && pdfUrl) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div>
            <div style={{ height: "750px" }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  defaultScale={1}
                  plugins={[zoomPluginInstance]}
                  fileUrl={pdfUrl}
                />
              </Worker>
            </div>
          </div>
        </div>
      );
    } else if (imageUrl) {
      return (
        <div style={{ textAlign: "center" }}>
          <img
            src={imageUrl}
            alt="Document"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      );
    } else {
      return (
        <DocViewer
          documents={pdfBlobURLs}
          style={{ minHeight: "inherit", marginTop: "30px" }}
          onDocumentChange={(doc: IDocument) => {
            setCurrentDocument(doc);
          }}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
            csvDelimiter: ",",
            pdfZoom: {
              defaultZoom: 0.6,
              zoomJump: 0.2,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      );
    }
  };

  return (
    <div className="px-10 py-10">
      <div
        className="card card-body shadow position-relative"
        style={{ minHeight: "100vh" }}
        ref={componentRef}
      >
        <div className="pdf-print btn btn-primary" onClick={handlePrint}>
          {t("Print & download")}
        </div>
        {isZoomable && (
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <ZoomOut>
              {(props: RenderZoomOutProps) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={props.onClick}
                >
                  <AiOutlineZoomOut size={20} />
                </Button>
              )}
            </ZoomOut>
            <ZoomIn>
              {(props: RenderZoomInProps) => (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={props.onClick}
                >
                  <AiOutlineZoomIn size={20} />
                </Button>
              )}
            </ZoomIn>
          </div>
        )}

        {renderViewer()}
      </div>
    </div>
  );
};

export default React.memo(DocsViewer);
