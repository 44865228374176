import React from "react";
interface ImageProps {
  base64String: any;
}
const Base64Image: React.FC<ImageProps> = ({ base64String }) => {
  const uri = base64String;
  const converter = atob(uri);
  return <img src={`data:image/png;base64,` + uri} alt={converter} />;
};

export default Base64Image;
