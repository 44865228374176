import React from "react";
import { useSelector } from "react-redux";

interface PermissionComponentProps {
  pageName: string;
  permissionIdentifier: string;
  moduleName: string;
  children: React.ReactNode;
}

interface PermissionObject {
  action: string;
  subject: string;
  moduleName: string;
}

const PermissionComponent: React.FC<PermissionComponentProps> = ({
  pageName,
  moduleName,
  permissionIdentifier,
  children,
}) => {
  const permissions = useSelector(
    (state: any) =>
      state?.Reducer?.selectedTenantInfo?.infomationOfLoggedUser?.permissions ||
      []
  );

  const hasPermission = permissions.some(
    (permission: PermissionObject) =>
      permission.subject.replace(/\n/g, "").toLowerCase() ===
        pageName.replace(/\n/g, "").toLowerCase() &&
      permission.moduleName.replace(/\n/g, "").toLowerCase() ===
        moduleName.replace(/\n/g, "").toLowerCase() &&
      permission.action.replace(/\n/g, "").toLowerCase() ===
        permissionIdentifier.replace(/\n/g, "").toLowerCase()
  );

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionComponent;
