import { toast } from "react-toastify";
const DymoLabel = async (content: any) => {
  const Dymo = require("dymojs"),
    dymo = new Dymo();
  var labelXml = `${content}`;
  try {
    const printers = await dymo.getPrinters();
    if (printers.length === 0) {
      console.error("No Dymo printers found.");
      return;
    }
    await dymo.print("DYMO LabelWriter 450", labelXml);
  } catch (error) {
    toast.error("Error in Dymo Printer");
  }
};
export default DymoLabel;
