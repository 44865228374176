import { toast } from "react-toastify";
export const DymoMultiPrint = async (content: any) => {
  const Dymo = require("dymojs"),
    dymo = new Dymo();
  for (const item of content) {
    var labelXml = `${item}`;
    try {
      const printers = await dymo.getPrinters();
      if (printers.length === 0) {
        console.error("No Dymo printers found.");
        return;
      }
      await dymo.print("DYMO LabelWriter 450", labelXml);
    } catch (error) {
      toast.error("Error in Dymo Printer");
    }
  }
};
