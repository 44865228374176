import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { addDays, format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { RxCross2 } from "react-icons/rx";

interface PickerProps {
  onDateChange: (
    startDate: string,
    endDate: string,
    dateFilter: string
  ) => void;
}

const Picker: React.FC<PickerProps> = ({ onDateChange }) => {
  const now = new Date();

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Open the Popover and reset the dates
  const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the Popover
  const handleClose = () => {
    setAnchorEl(null);
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const handleDateChange = (ranges: any) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    setRange([ranges.selection]);

    if (newStartDate && newEndDate) {
      console.log(newStartDate, "newStartDate");

      let startDate = format(newStartDate, "MM/dd/yyyy");
      let endDate = format(newEndDate, "MM/dd/yyyy");

      const dateFilter = `${startDate} to ${endDate}`;
      console.log(dateFilter, "dateFilter");

      // Adjust newStartDate and newEndDate to midnight UTC
      const startDateISO = new Date(
        Date.UTC(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate()
        )
      ).toISOString();

      const endDateISO = new Date(
        Date.UTC(
          newEndDate.getFullYear(),
          newEndDate.getMonth(),
          newEndDate.getDate()
        )
      ).toISOString();

      // Pass adjusted dates to onDateChange
      onDateChange(startDateISO, endDateISO, dateFilter);

      setInputValue(`${startDate} - ${endDate}`);
    }
  };

  useEffect(() => {
    const now = new Date(); // Current date
    const defaultEndDate = now; // Set to today
    const defaultStartDate = new Date(
      now.getFullYear(),
      now.getMonth() - 4,
      now.getDate() + 1
    ); // Set to 6 months before

    // Using moment.utc to handle dates consistently
    const localeDateStart = moment.utc(defaultStartDate).format("M/D/YYYY");
    const localeDateEnd = moment.utc(defaultEndDate).format("M/D/YYYY");

    // Pass the updated start and end dates as ISO strings
    const dateFilter = `${localeDateStart} to ${localeDateEnd}`;

    onDateChange(
      defaultStartDate.toISOString(),
      defaultEndDate.toISOString(),
      dateFilter
    );

    setInputValue(`${localeDateStart} - ${localeDateEnd}`);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "date-picker-popover" : undefined;

  const orientation = window.matchMedia("(max-width: 700px)").matches
    ? "vertical"
    : "horizontal";

  console.log(inputValue, "inputValue");

  return (
    <div>
      <input
        onClick={handleOpen}
        value={inputValue}
        readOnly
        className="form-control mb-lg-0 h-30px rounded-2 fs-8"
        placeholder="MM/DD/YYYY"
        style={{
          backgroundColor: "#57b35c",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2 }}>
          <div className="d-flex justify-content-end">
            <IconButton onClick={handleClose} aria-label="delete">
              <RxCross2 />
            </IconButton>
          </div>
          <Divider className="m-2" />
          <DateRangePicker
            ranges={range}
            inputRanges={[]}
            // @ts-ignore
            orientation={orientation}
            onChange={handleDateChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            maxDate={addDays(new Date(), 0)}
          />
        </Box>
      </Popover>
    </div>
  );
};

export default React.memo(Picker);
