import HttpClient from "HttpClient";
import apiRoutes from "../../Routes/Routes.json";

const GetAllNotification = (obj) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.GetAllNotification}`,
    obj
  );
};
const SaveNotification = (obj) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.SaveNotification}`,
    obj
  );
};
const getAllUserLookup = (userType) => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.getAllUserLookup}/${userType}/Lookup`
  );
};

const getNotificationTypeLookup = () => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.GetNotificationTypeLookup}`
  );
};

// DynamicSingleGrid
const singleGridTabsConfiguration = (tableId) => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.singleGridTabsConfiguration}/${tableId}/SingleUI`
  );
};

const getTablesSingleGrid = () => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.getTablesSingleUi}`
  );
};

const getSingleUiDynamicGrid = (payload) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.getSingleUiDynamicGrid}`,
    payload
  );
};

const saveSingleUiColumn = (payload) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.saveSingleUiColumn}`,
    payload
  );
};

// DynamicSplitPaneView
const getMainFilterSplitPane = () => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.getMainFilterSplitPane}`
  );
};

const getTabsForSplitPane = () => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.getTabsForSplitPane}`
  );
};

const saveColumnsSplitPane = (payload) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.saveColumnSplitPane}`,
    payload
  );
};

const dynamicGridSplitPane = (payload) => {
  return HttpClient().post(
    `/${apiRoutes.MiscellaneousManagement.dynamicGridSplitPane}`,
    payload
  );
};

//DynamicGrid
const dynamicGridTabs = () => {
  return HttpClient().get(
    `/${apiRoutes.MiscellaneousManagement.dynamicGridTabs}`
  );
};

const MiscellaneousService = {
  GetAllNotification,
  SaveNotification,
  getAllUserLookup,
  singleGridTabsConfiguration,
  getTablesSingleGrid,
  getSingleUiDynamicGrid,
  saveSingleUiColumn,
  getMainFilterSplitPane,
  getTabsForSplitPane,
  saveColumnsSplitPane,
  dynamicGridSplitPane,
  dynamicGridTabs,
  getNotificationTypeLookup
};

export default MiscellaneousService;
