import Files from "./SectionsUi/Files";
import RequisitionFields from "./SectionsUi/RequisitionFields";
import SignatureText from "./SectionsUi/SignatureText";
export const renderSwitch = (props: any) => {
  console.log(props, "renderSwitch");
  const { sectionDisplayName, fieldsInfo, sectionId } = props;
  switch (true) {
    case sectionDisplayName === "Files":
      return (
        <Files
          fieldsInfo={fieldsInfo}
          RequisitionId={props.RequisitionId}
          RequisitionOrderId={props.RequisitionOrderId}
          RecordId={props.RecordId}
          RequisitionType={props.RequisitionType}
          loadData={props.loadData}
        />
      );
    case sectionId === 13 || sectionId === 14:
      return <SignatureText fieldsInfo={fieldsInfo} />;
    case sectionDisplayName !== "Files" ||
      sectionDisplayName !== "Requisition" ||
      sectionId !== 13 ||
      sectionId !== 14:
      return <RequisitionFields fieldsInfo={fieldsInfo} />;
    default:
      return "";
  }
};
