import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";

interface DonutChartData {
  requisitionType: string;
  requisitionCount: number;
  percentage: number;
}

function OrderByType({
  donutChartData = [],
}: {
  donutChartData: DonutChartData[];
}) {
  // Mapping API data to the format needed by the Google Chart
  const chartData = [
    ["Requisition Type", "Percentage"], // Chart columns
    ...donutChartData.map((item) => [item.requisitionType, item.percentage]),
  ];

  const options = {
    pieHole: 0.4, // For a doughnut chart
    is3D: false, // Disable 3D effect
    legend: {
      position: "bottom",
      maxLines: 3, // Allow multi-line legends when there are plenty of items
    },
    chartArea: {
      left: 20,
      right: 20,
      top: 20,
      bottom: 50, // Leave space for the legend
      width: "100%",
      height: "75%", // Adjust the chart height for proper layout
    },
  };

  return (
    <div className="mt-4">
      <div className="card shadow-sm" style={{ borderRadius: "12px" }}>
        <div className="mb-2 p-4">
          <h5 className="card-title">Order By Type</h5>
          <Divider />
        </div>
        <div className="d-flex justify-content-center">
          <div id="chart" style={{ width: "50%" }}>
            <Chart
              chartType="PieChart"
              data={chartData}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderByType;
