import { MenuItem, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledDropButtonThreeDots,
  StyledDropMenuMoreAction,
} from "../../../../Utils/Style/Dropdownstyle";
import BootstrapModal from "react-bootstrap/Modal";
import { RequisitionReportShare } from "../../../../Services/Requisition/RequisitionReports/RequisitionReport";
import { reactSelectSMStyle, styles } from "../../../../Utils/Common";
import Select, { ActionMeta, MultiValue } from "react-select";
import LookupsFunctions from "../../../ManageNotification/LookupsFunctions";
import useLang from "./../../../../Shared/hooks/useLanguage";

type OptionType = { value: number; label: string }; // Added label for completeness

function ScheduledRow({ row, onDelete, userLookup }: any) {
  const { t } = useLang();
  const [anchorEl, setAnchorEl] = React.useState({
    dropdown1: null,
    dropdown2: null,
    dropdown3: null,
    dropdown4: null,
  });
  const handleClick = (event: any, dropdownName: any) => {
    setAnchorEl({ ...anchorEl, [dropdownName]: event.currentTarget });
  };
  const handleClose = (dropdownName: string) => {
    setAnchorEl({ ...anchorEl, [dropdownName]: null });
  };

  const handleDelete = (id: number) => {
    onDelete(id);
  };

  const [openalert, setOpenAlert] = useState(false);
  const handleCloseAlert = () => setOpenAlert(false);
  const handleClickOpen = (item: any, status: string) => {
    handleClose("dropdown2");
    setOpenAlert(true);
  };
  const [openShare, setOpenShare] = useState(false);
  const handleCloseShare = () => setOpenShare(false);
  const handleShareOpen = (item: any, status: string) => {
    handleClose("dropdown2");
    setOpenShare(true);
    setMultiSelect([]);
  };

  const [multiSelect, setMultiSelect] = useState<OptionType[]>([]);

  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setMultiSelect(newValue as OptionType[]); // Directly set OptionType[] here
  };

  const ScheduledShare = async () => {
    const obj = {
      reportID: row.id,
      userIds: multiSelect.map((option) => option.value),
    };
    let resp = await RequisitionReportShare(obj);
    setOpenShare(false);
  };

  return (
    <>
      <TableRow className="h-30px" key={row.id}>
        <TableCell>
          <div className="d-flex justify-content-center">
            <StyledDropButtonThreeDots
             id={`SchduledReport3Dots_${row.id}`}
              aria-haspopup="true"
              onClick={(event) => handleClick(event, "dropdown2")}
              className="btn btn-light-info btn-sm btn-icon moreactions min-w-auto rounded-4"
            >
              <i className="bi bi-three-dots-vertical p-0 icon"></i>
            </StyledDropButtonThreeDots>
            <StyledDropMenuMoreAction
              
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl.dropdown2}
              open={Boolean(anchorEl.dropdown2)}
              onClose={() => handleClose("dropdown2")}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem className="w-100px p-0">
                <a
                  onClick={() => handleClickOpen(row, row.id)}
                  className="w-100px text-dark"
                  id="SchduledReportDelete"
                >
                  <i
                    className="fa fa-trash text-danger mr-2"
                    aria-hidden="true"
                  ></i>
                  {t("Delete")}
                </a>
              </MenuItem>
            </StyledDropMenuMoreAction>
          </div>
        </TableCell>
        <TableCell id={`ReportName_${row.id}`} sx={{ width: "max-content" }}>
          <div className="d-flex justify-content-between cursor-pointer">
            <div
              style={{
                width: "max-content",
              }}
            >
              {row.reportName}
            </div>
          </div>
        </TableCell>
        <TableCell
          id={`Fraquency_${row.id}`}
          sx={{
            width: "max-content",
          }}
        >
          <div className="d-flex justify-content-between cursor-pointer" >
            <div
              style={{
                width: "max-content",
              }}
            >
              {row.frequency}
            </div>
          </div>
        </TableCell>
        <TableCell
          id={`SchduledDate_${row.id}`}
          sx={{
            width: "max-content",
          }}
        >
          <div className="d-flex justify-content-between cursor-pointer" >
            <div
              style={{
                width: "max-content",
              }}
            >
              {row.scheduledDate}
            </div>
          </div>
        </TableCell>
        <TableCell
          sx={{
            width: "max-content",
          }}
        >
          <div className="d-flex justify-content-around cursor-pointer">
            <button
              id={`ShareButton_${row.id}`}
              className="btn btn-icon btn-sm fw-bold btn-info btn-icon-light"
              onClick={() => handleShareOpen(row, row.id)}
            >
              <i className="bi bi-share-fill"></i>
            </button>
          </div>
        </TableCell>
      </TableRow>
      <BootstrapModal
        BootstrapModal
        show={openalert}
        onHide={handleCloseAlert}
        backdrop="static"
        keyboard={false}
      >
        <BootstrapModal.Header closeButton className="bg-light-primary m-0 p-5">
          <h4>{t("Delete Record")}</h4>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {t("Are you sure you want to delete this record ?")}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="p-0">
          <button
            id="CancelDeleteRecord"
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseAlert}
          >
            {t("Cancel")}
          </button>
          <button
            id="ConfirmDeleteRecord"
            type="button"
            className="btn btn-danger m-2"
            onClick={() => handleDelete(row.id)}
          >
            {t("Delete")}
          </button>
        </BootstrapModal.Footer>
      </BootstrapModal>
      <BootstrapModal
        BootstrapModal
        show={openShare}
        onHide={handleCloseShare}
        backdrop="static"
        keyboard={false}
      >
        <BootstrapModal.Header
          closeButton
          className="bg-light-primary m-0 p-5 py-2"
        >
          <h4>{t("Share With Users")}</h4>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="py-2">
          <label>{t("Users")}</label>
          <Select<OptionType, true>
            theme={(theme) => styles(theme)}
            isMulti
            menuPortalTarget={document.body}
            options={userLookup}
            styles={reactSelectSMStyle}
            name="UserShareList"
            onChange={handleChange}
            value={multiSelect}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="p-0">
          <button
            id="CancelShareWithUser"
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseShare}
          >
            {t("Cancel")}
          </button>
          <button
            id="ShareWithUser"
            type="button"
            className="btn btn-danger "
            onClick={() => ScheduledShare()}
          >
            {t("Share")}
          </button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
}

export default ScheduledRow;
