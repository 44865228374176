import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useLang from "Shared/hooks/useLanguage";

interface CheckboxProps {
  id: string;
  type: string;
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}
interface IValues {
  listone: string | number | undefined;
  listtwo: string | number | undefined;
}
interface IObj {
  id: string | never;
  name: string | never;
  list: boolean | never;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  type,
  name,
  handleChange,
  isChecked,
}) => {
  return (
    <label className="form-check form-check-sm form-check-solid me-5 border">
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleChange}
        checked={isChecked}
        className="form-check-input"
      />
    </label>
  );
};
interface IProps {
  facilities: any;
  setValues: React.SetStateAction<any>;
  values: any;
  sports2: any;
  setSports2: any;
}

const Facilities: React.FC<IProps> = ({
  facilities,
  setValues,
  values,
  sports2,
  setSports2,
}) => {
  const { t } = useLang();
  const [isCheckAll, setIsCheckAll] = useState({
    testList: false,
    selectedTestList: false,
  });
  const [isCheckTestList, setisCheckTestList] = useState<any[]>([]);
  const [isCheckedSelectedList, setisCheckedSelectedList] = useState<any[]>([]);
  const [selectedItemsToPop, setSelectedItemsToPop] = useState<any>([]);
  const [dragItemIndex, setDragItemIndex] = useState<any>();
  const [checkedList, setCheckedList] = useState({
    testList: [],
    selectedTestList: [],
  });
  const [dragOverItemIndex, setDragOverItemIndex] = useState<IValues>({
    listone: "",
    listtwo: "",
  });

  const [sports, setSports] = useState<any[]>([]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    if (name === "testList") {
      if (!isCheckAll.testList) {
        // Get an array of all the facility IDs and names
        const allFacilities = filteredSports.map((li: any) => ({
          id: li.id,
          facilityName: li.facilityName,
        }));

        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            testList: [...allFacilities],
          };
        });

        setisCheckTestList(allFacilities.map((facility: any) => facility.id));
      } else {
        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            testList: [],
          };
        });

        setisCheckTestList([]);
      }

      setIsCheckAll((prevVal: any) => ({
        ...prevVal,
        testList: !prevVal.testList,
      }));
    }

    if (name === "selectedtestList") {
      if (!isCheckAll.selectedTestList) {
        const allFacilities = filteredSports2.map((li: any) => ({
          id: li.id,
          facilityName: li.facilityName,
        }));
        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            selectedTestList: [...allFacilities],
          };
        });

        setisCheckedSelectedList(allFacilities.map((li: any) => li.id));
      }
      if (isCheckAll.selectedTestList) {
        setisCheckedSelectedList([]);
      }
      setIsCheckAll((preVal: any) => {
        return {
          ...preVal,
          selectedTestList: !isCheckAll.selectedTestList,
        };
      });
    }
  };

  useEffect(() => {
    setSports(
      facilities
        .map((facility: any) => ({
          id: facility.value,
          facilityName: facility.label,
        }))
        .filter(
          (facility: any) =>
            !sports2?.some((item: any) => item.id === facility.id)
        )
    );
    setValues((preVal: any) => {
      return {
        ...preVal,
        facilitiesIds: sports2?.map((item: any) => item.id),
      };
    });
  }, [sports2, facilities]);

  const handleChangeTestList = (e: any, id: any, name: any, index: any) => {
    // Check if the item is already in isCheckTestList
    const isSelected = isCheckTestList.includes(id);

    if (isSelected) {
      // Remove the item from isCheckTestList
      setisCheckTestList(isCheckTestList.filter((item) => item !== id));
    } else {
      // Add the item to isCheckTestList
      setisCheckTestList([...isCheckTestList, id]);
    }

    // Then, update checkedList.testList
    setCheckedList((prevVal: any) => {
      if (isSelected) {
        // If it was selected, remove it from testList
        return {
          ...prevVal,
          testList: checkedList.testList.filter((item: any) => item.id !== id),
        };
      } else {
        // If it wasn't selected, add it to testList
        return {
          ...prevVal,
          testList: [
            ...checkedList.testList,
            { id, facilityName: name, index },
          ],
        };
      }
    });
  };

  const handleChangeSelectedTestList = (
    e: any,
    id: any,
    name: any,
    index: any
  ) => {
    // Check if the item is already in isCheckedSelectedList
    const isSelected = isCheckedSelectedList.includes(id);

    if (isSelected) {
      // Remove the item from isCheckedSelectedList
      setisCheckedSelectedList(
        isCheckedSelectedList.filter((item) => item !== id)
      );
      // Remove the item from selectedItemsToPop
      setSelectedItemsToPop(
        selectedItemsToPop.filter((item: any) => item.id !== id)
      );
      // Remove the item from checkedList.selectedTestList
      setCheckedList((prevVal) => {
        return {
          ...prevVal,
          selectedTestList: checkedList.selectedTestList.filter(
            (item: any) => item.id !== id
          ),
        };
      });
    } else {
      // Add the item to isCheckedSelectedList
      setisCheckedSelectedList([...isCheckedSelectedList, id]);
      // Add the item to selectedItemsToPop
      setSelectedItemsToPop([
        ...selectedItemsToPop,
        { id, facilityName: name, index },
      ]);
      // Add the item to checkedList.selectedTestList
      setCheckedList((prevVal: any) => {
        return {
          ...prevVal,
          selectedTestList: [
            ...checkedList.selectedTestList,
            { id, facilityName: name, index },
          ],
        };
      });
    }
  };
  useEffect(() => {
    forwardPush();
  }, [checkedList.testList]);

  useEffect(() => {
    backwardPush();
  }, [checkedList.selectedTestList]);
  const forwardPush = () => {
    if (checkedList.testList.length !== 0) {
      let originalArrCopy = Array.isArray(sports2) ? [...sports2] : [];
      let arrWithCheckedItems = Array.isArray(checkedList.testList)
        ? [...checkedList.testList]
        : [];

      let concatedArray = [
        ...(originalArrCopy || []),
        ...(arrWithCheckedItems || []),
      ];
      let currentArray = [...sports];

      // Remove the pushed items from the original sports array
      let updatedSports = currentArray.filter((item: any) => {
        return !concatedArray.find((el) => el.id === item.id);
      });

      setSports(updatedSports);
      setSports2(concatedArray);
      setValues((preVal: any) => {
        return {
          ...preVal,
          facilitiesIds: [
            ...new Set(
              (preVal?.facilitiesIds || []).concat(
                concatedArray.map((item: any) => item.id)
              )
            ),
          ],
        };
      });
      setCheckedList((preVal: any) => {
        return {
          ...preVal,
          testList: [],
        };
      });
      setisCheckTestList([]);
      setIsCheckAll((prevVal) => ({
        ...prevVal,
        testList: false, // Uncheck the checkbox
      }));
    }
  };

  const backwardPush = () => {
    if (checkedList.selectedTestList.length !== 0) {
      let orignalArrCopy = [...sports];
      let arrWithCheckedItems: any = [...checkedList.selectedTestList];

      let concatedArray = [
        ...new Set(orignalArrCopy.concat(arrWithCheckedItems)),
      ];
      let currentArray = [...sports2];

      let updatedSports = currentArray.filter((item: any) => {
        return !concatedArray.find((el: any) => el.id === item.id);
      });
      setSports2(updatedSports);
      setSports(concatedArray);
      setCheckedList((preVal: any) => {
        return {
          ...preVal,
          selectedTestList: [],
        };
      });
      setValues((preVal: any) => {
        return {
          ...preVal,

          facilitiesIds: updatedSports.map((item: any) => item.id),
        };
      });
      setisCheckedSelectedList([]);
      setIsCheckAll((prevVal) => ({
        ...prevVal,
        selectedTestList: false, // Uncheck the checkbox
      }));
    }
  };
  //////////////////////////// Search ///////////////////////////////
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  // const [filteredFacilities, setFilteredFacilities] = useState([])
  const filteredSports = sports.filter((facility: any) =>
    facility?.facilityName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredSports2 = sports2?.filter((facility: any) =>
    facility?.facilityName.toLowerCase().includes(searchQuery2.toLowerCase())
  );
  //////////////////////////// Search ///////////////////////////////

  const moveToSports2 = () => {
    if (Array.isArray(sports)) {
      let sportsCopy = [...sports];
      let sports2Copy = Array.isArray(sports2) ? [...sports2] : [];
      let newSports2 = [...sports2Copy, ...sportsCopy];
      setSports2(newSports2);
      setSports([]);
    }
  };

  const moveToSports = () => {
    if (Array.isArray(sports2)) {
      let sports2Copy = [...sports2];
      let sportsCopy = Array.isArray(sports) ? [...sports] : [];
      let newSports = [...sportsCopy, ...sports2Copy];
      setSports(newSports);
      setSports2([]);
    }
  };
  console.log(sports2, "sports2");

  return (
    <>
      <div className="py-0">
        <div className="card shadow-sm rounded border border-warning">
          <div className="card-header px-4 d-flex justify-content-between align-items-center rounded bg-light-warning min-h-40px">
            <h6 className="text-warning mb-0">{t("Facilities")}</h6>
          </div>
          <div className="card-body py-md-4 py-3 px-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="d-flex align-items-center flex-wrap justify-content-around">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <span className="fw-bold">{t("All Facilities")}</span>
                    <input
                      id="FacilityUserAllFacilitySearch"
                      type="text"
                      name=""
                      className="form-control bg-white mb-3 mb-lg-0 h-30px rounded-2 fs-8 w-100"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                      <div className="align-items-center bg-secondary d-flex h-40px justify-content-between px-4 rounded">
                        <span className="fw-bold">{t("All List")}</span>
                      </div>

                      <ul className="list-group rounded-0 list-group-even-fill h-325px scroll">
                        {filteredSports?.map((items: any, index: number) => (
                          <li
                            id={`FacilityUserAllFacility_${items.id}`}
                            key={index}
                            className={
                              dragOverItemIndex?.listone === index
                                ? "list-group-item next-position px-2 py-1 border-0 cursor-pointer "
                                : "list-group-item px-2 py-1 border-0 cursor-pointer"
                            }
                            onClick={(e) =>
                              handleChangeTestList(
                                e,
                                items?.id,
                                items?.facilityName,
                                index
                              )
                            }
                          >
                            <div className="d-flex">
                              <React.Fragment key={items?.id}>
                                {items?.facilityName}
                              </React.Fragment>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="align-items-center d-flex flex-md-column mt-2 justify-content-center gap-2 px-3">
                    <span
                      className="align-content-center bg-warning d-flex justify-content-center p-3 rounded-1"
                      onClick={moveToSports2}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        id="FacilityUserSelectAllFacility"
                        style={{ fontSize: "20px", color: "white" }}
                        className="fa"
                      >
                        &#xf101;
                      </i>
                    </span>
                    <span
                      className="align-content-center bg-info d-flex justify-content-center p-3 rounded-1"
                      onClick={moveToSports}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        id="FacilityUserDeselectAllFacility"
                        style={{ fontSize: "20px", color: "white" }}
                        className="fa"
                      >
                        &#xf100;
                      </i>
                    </span>
                  </div>
                  <div className="col-lg-6 col-md-5 col-sm-12">
                    <span className="fw-bold required">
                      {t("Selected Facilities")}
                    </span>
                    <input
                      id="FacilityUserSelectedFacilitySearch"
                      type="text"
                      name=""
                      className="form-control bg-white mb-3 mb-lg-0 h-30px rounded-2 fs-8 w-100"
                      placeholder="Search..."
                      value={searchQuery2}
                      onChange={(e) => setSearchQuery2(e.target.value)}
                    />
                    <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                      <div className="align-items-center bg-secondary d-flex h-40px justify-content-between px-4 rounded">
                        <span className="fw-bold">{t("Selected List")}</span>
                      </div>

                      <ul className="list-group rounded-0 list-group-even-fill h-325px scroll">
                        {filteredSports2?.map((item: any, index: number) => (
                          <li
                            id={`FacilityUserSelectedFacility_${item.id}`}
                            key={index}
                            className={
                              dragOverItemIndex?.listtwo === index
                                ? "list-group-item next-position px-2 py-1 border-0 cursor-pointer "
                                : "list-group-item px-2 py-1 border-0 cursor-pointer"
                            }
                            onClick={(e) => {
                              if (!item?.isDisabled) {
                                handleChangeSelectedTestList(
                                  e,
                                  item.id,
                                  item.facilityName,
                                  index
                                );
                              } else {
                                toast.info(
                                  "Primary facility can not be removed"
                                );
                              }
                            }}
                          >
                            <div className="d-flex">
                              <React.Fragment key={item.id}>
                                {item.facilityName}
                              </React.Fragment>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
