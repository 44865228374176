import React from 'react'
interface StatusProps {
  cusText: string;
  cusClassName: string;
}

const Status: React.FC<StatusProps> = ({ cusText, cusClassName }) => {
  return (
    <span className={`badge badge-pill px-4 py-2 my-1 rounded-4 fw-400 fa-1x ${cusClassName}`}>
      {cusText}
    </span>
  );
};

export default Status;