import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearReduxStore } from "Redux/Actions/Index";
import store from "Redux/Store/AppStore";
import AccountService from "Services/Account/AccountService";

const useLogoutListener = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "logout") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate, dispatch]);

  const logout = async () => {
    // api call for blacklisting used token
    try {
      await AccountService.userLogout();
    } catch (error) {
      // Redirect to login page
      navigate("/login");
      navigate(0);
    }

    sessionStorage.clear();
    localStorage.clear();

    // clearing store for closing user session
    store.dispatch(clearReduxStore());

    localStorage.setItem("logout", Date.now().toString());

    // Redirect to login page
    navigate("/login");
    navigate(0);
  };

  return logout;
};

export default useLogoutListener;
