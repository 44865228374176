import useLang from "./../../hooks/useLanguage";
const RawText = (props: any) => {
  const { t } = useLang();

  return (
    <>
      {props?.sectionId === 36 ? (
        <div
          className={`${props?.parentDivClassName} pb-2 border-solid border-bottom border-gray-300 mb-3 mt-1`}
        >
          <p className="text-wrap fw-600 text-dark text-capitalize p-0 m-0">
            {t(props?.label)}
          </p>
        </div>
      ) : props?.sectionId === 17 ? (
        <>
          <h6 className="text-primary text-wrap mb-5">{t(props?.label)}</h6>
        </>
      ) : (
        <div className={props?.parentDivClassName}>
          <p className="text-gray-600 text-wrap">{t(props?.label)}</p>
        </div>
      )}
    </>
  );
};

export default RawText;
