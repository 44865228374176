import { t } from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { useLocation } from "react-router-dom";
import { FindIndex } from "Utils/Common/CommonMethods";
import { assignFormValues } from "../../../Utils/Auth";
import { dateFormatSetter } from "../../../Utils/Common/Requisition";

const DateTimePickerInput = (props: any) => {
  let location = useLocation();
  const today = new Date();
  const [value, setValue] = useState<any | undefined>();
  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput == inputElementReactSelect.current ||
      props?.errorFocussedInput == inputElementReactSelect.current.id
    ) {
      inputElementReactSelect.current.focus();
    }
  }, [props?.errorFocussedInput]);
  useEffect(() => {
    let formattedDate = dateFormatSetter(props.defaultValue);
    setValue(formattedDate);
  }, [props.defaultValue]);
  const handleChange = async (e: any) => {
    let formattedDate = dateFormatSetter(e);
    let updatedDate = moment(formattedDate).format("MM/DD/YYYY");
    setValue(formattedDate);
    let newInputs = await assignFormValues(
      props.Inputs,
      props.index,
      props.depControlIndex,
      props.fieldIndex,
      e ? updatedDate : "",
      props.isDependency,
      props.repeatFieldSection,
      props.isDependencyRepeatFields,
      props.repeatFieldIndex,
      props.repeatDependencySectionIndex,
      props.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    if (props?.ArrayReqId) {
      let infectiousDataCopy = JSON?.parse(
        JSON?.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props?.ArrayReqId)
      ].sections = newInputs;
      props?.setInfectiousData([...infectiousDataCopy]);
    } else {
      props.setInputs(newInputs);
    }
    props.fields.enableRule = "";
  };
  const divElement = useRef<HTMLDivElement | null>(null); // Initialize ref for div
  useEffect(() => {
    // Scroll to the div if props.error is present
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    props.setErrorFocussedInput && props.setErrorFocussedInput();
  }, [props?.errorFocussedInput]);
  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-4 d-flex flex-column`
            : "col-lg-6 col-md-6 col-sm-12 mb-4"
        }
      >
        <div
          className={props?.name == "PatientDescription" ? "d-none" : ""}
          id={props?.name}
          ref={inputElementReactSelect}
          tabIndex={-1}
        >
          {" "}
        </div>
        <label
          className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
          htmlFor={props.id}
        >
          {t(props.label)}
        </label>
        <DatePicker
          format="MM/dd/yyyy"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          maxDate={
            props.name === "DateofCollection" ||
            props.name === "DateReceived" ||
            props.name === "DOB" ||
            props.name === "SubscriberDOB" ||
            props.name === "AccidentDate"
              ? today
              : undefined
          }
          onChange={(e: any) => handleChange(e)}
          value={dateFormatSetter(value) ?? ""}
          disabled={props?.name === "PatientDescription" ? true : false}
          id={props?.name}
        />

        {props.field?.enableRule && (
          <div className="form__error">
            <span>{t(props.field?.enableRule)}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default DateTimePickerInput;
