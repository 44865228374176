import { Divider } from "@mui/material";
import React from "react";
import Chart from "react-google-charts";

function OrderDisposition({ pieChartDatas }: any) {
  // Transform the API data for the chart
  const transformedData = [
    ["Workflow Status", "Count"], // Chart headers
    ...pieChartDatas.map((item: any) => [
      item.workFlowStatus,
      item.workFlowStatusCount,
    ]),
  ];

  const options = {
    pieHole: 0, // For a doughnut chart
    is3D: true, // Disable 3D effect
    legend: {
      position: "bottom",
      alignment: "left", // Center-align the legend
      maxLines: 3, // Allow multi-line legends when there are plenty of items
      textStyle: { fontSize: 12 }, // Adjust font size for better spacing
    },
    chartArea: {
      left: 20,
      right: 20,
      top: 20,
      bottom: 50, // Leave space for the legend
      width: "100%",
      height: "75%", // Adjust the chart height for proper layout
    },
  };

  return (
    <div className="mt-4">
      <div className="card shadow-sm" style={{ borderRadius: "12px" }}>
        <div className="mb-2 p-4">
          <h5 className="card-title">Order Disposition</h5>
          <Divider />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center">
            <div id="chart" style={{ width: "50%" }}>
              <Chart
                chartType="PieChart"
                data={transformedData}
                options={options}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDisposition;
