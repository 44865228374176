import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getIntegrationDetailById,
  getMarketPlaceGetAll,
  labIntegrationRequest,
} from "Services/MarketPlace";
import { Loader } from "Shared/Common/Loader";
import useLang from "Shared/hooks/useLanguage";
import usePagination from "Shared/hooks/usePagination";
import CustomPagination from "Shared/JsxPagination";
import BreadCrumbs from "Utils/Common/Breadcrumb";
import { sortByCreationDate } from "Utils/consts";

function MarketPlace() {
  const { t } = useLang();
  const initialSearchValue = { searchString: "", integrationType: "" };
  const [searchRequest, setSearchRequest] = useState(initialSearchValue);
  const [openAlert, setOpenAlert] = useState(false);
  const [cards, setCards] = useState<any>([]);
  const [dataById, setDataById] = useState<any>([]);
  const [dataByIdLoader, setDataByIdLoader] = useState(false);
  const [initialRender, setInitialRender] = useState(false);
  const labId = useSelector(
    (reducers: any) => reducers.Reducer.selectedTenantInfo.tenantId
  );

  const {
    curPage,
    pageSize,
    total,
    totalPages,
    pageNumbers,
    nextPage,
    prevPage,
    showPage,
    setPageSize,
    setTotal,
    setCurPage,
  } = usePagination();

  useEffect(() => {
    if (initialRender) {
      loadData(false);
    } else {
      setInitialRender(true);
    }
  }, [curPage, pageSize]);

  const loadData = async (loader: boolean, payload?: any) => {
    getMarketPlaceGetAll({
      pageNumber: curPage,
      pageSize: pageSize,
      queryModel: payload ?? searchRequest,
      sortColumn: sortByCreationDate?.clickedIconData,
      sortDirection: sortByCreationDate?.sortingOrder,
    })
      .then((res: any) => {
        setCards(res?.data?.data);
        setTotal(res?.data?.total);
      })
      .catch((err: any) => {
        console.trace(err, "err");
      });
  };

  const handleClickOpen = (id: number) => {
    setDataByIdLoader(true);
    setOpenAlert(true);
    getIntegrationDetailById(id)
      .then((res: any) => {
        setDataById(res?.data?.data);
        setDataByIdLoader(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
      })
      .finally(() => {
        setDataByIdLoader(false);
      });
  };

  const handleStartIntegration = () => {
    labIntegrationRequest({
      id: 0,
      labId: labId,
      integrationId: dataById.integrationId,
      description: dataById.description,
      status: "pending",
    })
      .then((res: any) => {
        toast.success(res.data.message);
        setOpenAlert(false);
      })
      .catch((err: any) => {
        console.trace(err, "err");
      });
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onInputChangeSearch = (event: any) => {
    setSearchRequest({
      ...searchRequest,
      searchString: event.target.value,
    });
    handleSearch(event.target.value);
  };

  const handleSearch = useCallback(
    _.debounce((searchTerm) => {
      const payload = {
        searchString: searchTerm.trim(),
        integrationType: "",
      };
      loadData(false, payload);
    }, 500),
    []
  );

  useEffect(() => {
    loadData(true);
  }, []);

  return (
    <>
      <Modal
        show={openAlert}
        onHide={handleCloseAlert}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-light-primary m-0 p-5">
          <h4>{t("Integrate")}</h4>
        </Modal.Header>
        <Modal.Body>
          {dataByIdLoader ? <Loader /> : dataById.description}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleCloseAlert}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="btn btn-primary m-2"
            disabled={dataByIdLoader}
            onClick={() => {
              handleStartIntegration();
              handleCloseAlert();
            }}
          >
            <span>{t("Start Integration")}</span>
          </button>
        </Modal.Footer>
      </Modal>
      <div id="kt_app_toolbar" className="app-toolbar py-2 py-lg-3">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <BreadCrumbs />
        </div>
      </div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="position-relative mb-5 mt-3">
            <IoSearchOutline
              className="position-absolute top-50 start-0 translate-middle-y ms-2"
              size={17}
              color="green"
            />
            <input
              type="text"
              name="itemName"
              className="form-control bg-white h-30px rounded-2 fs-8 ps-4 border-0 ps-10"
              placeholder={"Search Product"}
              value={searchRequest.searchString}
              onChange={onInputChangeSearch}
            />
          </div>
          <div className="row">
            {cards.map((card: any, index: number) => (
              <div
                key={index}
                className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4"
              >
                <Card className="card shadow-none rounded-3 h-100">
                  <div
                    className="card-body p-6 d-flex flex-column align-items-start"
                    style={{ height: "250px" }}
                  >
                    {card.integrationLogo ? (
                      <img
                        className="mb-3"
                        src={card.integrationLogo}
                        alt="Integration Logo"
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div
                        className="mb-3 bg-light d-flex align-items-center justify-content-center"
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <i className="fa fa-image text-muted"></i>
                      </div>
                    )}
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="text-black fw-500"
                    >
                      {card.integrationName}
                    </Typography>
                    <Button
                      className="btn btn-primary btn-sm mt-auto text-lo text-capitalize"
                      onClick={() => handleClickOpen(card.integrationId)}
                    >
                      Request Integrate
                    </Button>
                    <i
                      className="fa fa-flask bg-body opacity-10 position-absolute end-0 bottom-0 me-4 mb-2"
                      style={{ fontSize: "85px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          <CustomPagination
            curPage={curPage}
            nextPage={nextPage}
            pageNumbers={pageNumbers}
            pageSize={pageSize}
            prevPage={prevPage}
            showPage={showPage}
            total={total}
            totalPages={totalPages}
          />
        </div>
      </div>
    </>
  );
}

export default MarketPlace;
