import { createContext, ReactNode, useContext, useState } from "react";
import RequisitionType from "../../Services/Requisition/RequisitionTypeService";
import UserManagementService from "../../Services/UserManagement/UserManagementService";

export interface FilterDataI {
  tabId: number;
  pageSize: number;
  pageNumber: number;
  sortColumn: string;
  sortDirection: string;
  filters: any[];
}

type Context = {
  data: any;
  refetchData: any;
  refetch: any;
  setData: any;
  filterData: FilterDataI;
  setFilterData: any;
  searchValue: any;
  setSearchValue: any;
  loadAllResultData: any;
  selectedBox: any;
  setSelectedBox: any;
  loading: any;
  isSubmittingNextStepAction: any;
  apiCalls: any;
  value: any;
  setValue: any;
  open: boolean;
  setOpen: any;
  getFacilityLookup: any;
  getPanelLookup: any;
  panel: any;
  getLisStatus: any;
  lisstatus: any;
  setCheckedAll: any;
  checkedAll: boolean;
};

const context = createContext<Context>({} as Context);
export const intialValue = {
  tabId: 1,
  pageSize: 50,
  pageNumber: 1,
  sortColumn: "",
  sortDirection: "",
  filters: [],
};

export default function ToxResultDataContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [refetch, refetchData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState<any>(false);
  const [value, setValue] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [filterData, setFilterData] = useState<any>(intialValue);
  const [searchValue, setSearchValue] = useState({});
  const [data, setData] = useState<any>({
    gridHeaders: [],
    gridColumns: [],
    gridData: [],
    facilityLookup: [],
    total: 0,
  });
  const [selectedBox, setSelectedBox] = useState<any>({
    requisitionId: [],
  });
  const [isSubmittingNextStepAction, setisSubmittingNextStepAction] =
    useState(false);

  const loadData = async () => {
    let response;
    try {
      response = await RequisitionType.viewRequisitionTabs();
      return response?.data?.data;
    } catch (error) {
      return error;
    }
  };
  const loadAllResultData = async (shouldPageLoad = true) => {
    shouldPageLoad && setLoading(true);
    let searchParams = {
      tabId: filterData?.tabId,
      pageNumber: filterData?.pageNumber,
      pageSize: filterData?.pageSize,
      sortColumn: filterData?.sortColumn,
      sortDirection: filterData?.sortDirection,
      filters: filterData?.filters,
      IsLoadExpand: false,
      selectedRow: null,
    };
    let response: any;
    try {
      response = await RequisitionType.getToxicologyAllResultData(searchParams);
      setData((preVal: any) => {
        return {
          ...preVal,
          gridData: response?.data?.data,
          total: response?.data?.total,
        };
      });
      data.gridHeaders[0]?.tabHeaders && setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };
  const apiCalls = async () => {
    let gridHeadersArr = await loadData();
    let gridData = await loadAllResultData();
    setData((preVal: any) => {
      return {
        ...preVal,
        gridHeaders: gridHeadersArr,
        gridData: gridData?.data?.data,
      };
    });
    setLoading(false);
  };
  const getFacilityLookup = async () => {
    let response: any;
    try {
      response = await UserManagementService.GetFacilitiesLookup();
      setData((preVal: any) => {
        return {
          ...preVal,
          facilityLookup: response?.data,
        };
      });
    } catch (error) {
      return error;
    }
  };

  const [panel, setPanel] = useState([]);
  const getPanelLookup = async () => {
    let response: any;
    try {
      response = await RequisitionType.GetIDLISPanelLookup();
      setPanel(response?.data);
    } catch (error) {
      return error;
    }
  };
  const [lisstatus, setLisstatus] = useState([]);
  const getLisStatus = async () => {
    let response: any;
    try {
      response = await RequisitionType.GetToxLISPanelLookup();
      setLisstatus(response?.data);
    } catch (error) {
      return error;
    }
  };
  return (
    <context.Provider
      value={{
        data,
        setData,
        refetch,
        refetchData,
        filterData,
        setFilterData,
        searchValue,
        setSearchValue,
        loadAllResultData,
        selectedBox,
        setSelectedBox,
        loading,
        isSubmittingNextStepAction,
        apiCalls,
        value,
        setValue,
        open,
        setOpen,
        getFacilityLookup,
        getPanelLookup,
        panel,
        getLisStatus,
        lisstatus,
        checkedAll,
        setCheckedAll,
      }}
    >
      {children}
    </context.Provider>
  );
}
export const useToxResultDataContext = () => useContext(context);
