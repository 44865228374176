import { useState } from "react";
import MiscellaneousService from "../../Services/MiscellaneousManagement/MiscellaneousService";
import { PortalTypeEnum } from "Utils/Common/Enums/Enums";

function LookupsFunctions() {
  const [userLookup, setUserLookup] = useState([]);
  const [notificationTypeLookup, setNotificationTypeLookup] = useState([]);

  const getUserLookup = async (userType: number) => {
    let response = await MiscellaneousService.getAllUserLookup(userType);
    setUserLookup(response?.data?.result);
  };

  const getNotificationTypes = async () => {
    let response = await MiscellaneousService.getNotificationTypeLookup();
    setNotificationTypeLookup(response?.data?.result);
  };

  const userTypeLookUp = [
    {
      label: "Admin",
      value: PortalTypeEnum.Admin,
    },
    {
      label: "Facility",
      value: PortalTypeEnum.Facility,
    },
    {
      label: "Sales",
      value: PortalTypeEnum.Sales,
    },
  ];

  return {
    getNotificationTypes,
    userLookup,
    getUserLookup,
    notificationTypeLookup,
    userTypeLookUp,
  };
}

export default LookupsFunctions;
